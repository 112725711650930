import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITooltip } from "ev-tooltip";
import { UntypedFormBuilder, UntypedFormControl } from "@angular/forms";

@Component({
    selector: 'search-modal',
    templateUrl: './search-modal.component.html',
    styleUrls: ['./search-modal.component.scss'],
    standalone: false
})
export class SearchModalComponent implements OnInit {

  @Input() title: string;
  @Input() tooltip: ITooltip;
  @Input() loading: boolean = false;
  @Input() showModal: boolean = false;
  @Output() search: EventEmitter<string> = new EventEmitter();
  @Output() close: EventEmitter<boolean> = new EventEmitter();

  query = new UntypedFormControl('');
  searchForm = this.fb.group({ query: this.query });

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.close.emit(false)
  }

  searchQuery() {
    this.loading = true;

    this.search.emit(this.query.value)

    this.searchForm.reset()
  }

}
