import {Injectable} from '@angular/core';
import {Observable, of, throwError} from "rxjs";
import {Unit} from "../../commercial-sale/models/unit.model";
import {environment} from "../../../environments/environment";
import {catchError, map} from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {ToastService} from "../../shared/services/toast.service";
import {Agent} from "../../shared/models/agent.model";

@Injectable({
  providedIn: 'root'
})

export class PhotoRegistrationService {
  constructor(private http: HttpClient, private toastService: ToastService) {}

  searchEstates(searchQuery: string): Observable<Unit[]> {
    const queryLimit = 10;
    return this.http.get<{data: Unit[]}>(`${environment.apiUrl}/common/estates/search?limit=${queryLimit}&offset=1&query=${searchQuery}`).pipe(
      map(result => result.data)
    );
  }

  postImage(data: any): any {
      const formData = new FormData();
      formData.append('estateId', data.estateId);
      formData.append('File', data.image, data.image.name);

      return this.http.post(`${environment.apiUrl}/Commercial/Estate/${data.estateId}/Images/Upload`, formData).pipe(
        catchError((err) => {
          this.toastService.setMessage({ text: 'Noe gikk galt ved opplasting av bilde. Prøv igjen', type: 'error'})
          return throwError(err);
        })
      )
  }

  getImages(estateId: number): any {
    return this.http.get(`${environment.apiUrl}/Commercial/Estate/${estateId}/Images/Info`).pipe(
      map(result => result),
      catchError((err) => {
        this.toastService.setMessage({ text: 'Det oppstod en feil ved henting av bilder. Last inn siden på nytt.', type: 'error'})
        return throwError(err);
      })
    );
  }

  postMainImage(estateId: number, filename: string) {
    return this.http.post<any>(`${environment.apiUrl}/Commercial/Estate/${estateId}/Images/${filename}/Main`, {}).pipe(
      map(result => result),
      catchError((err) => {
        this.toastService.setMessage({ text: 'Det oppstod en feil ved bytte av forsidebilde. Prøv igjen.', type: 'error'})
        return throwError(err);
      })
    )
  }

  postArchiveImage(estateId: number, filename: string) {
    return this.http.post<any>(`${environment.apiUrl}/Commercial/Estate/${estateId}/Images/${filename}/Archive`, {}).pipe(
      map(result => result),
      catchError((err) => {
        this.toastService.setMessage({ text: 'Det oppstod en feil ved arkivering av bilde. Prøv igjen.', type: 'error'})
        return throwError(err);
      })
    )
  }

  postRecoverImage(estateId: number, filename: string) {
    return this.http.post<any>(`${environment.apiUrl}/Commercial/Estate/${estateId}/Images/${filename}/Recover`, {}).pipe(
      map(result => result),
      catchError((err) => {
        this.toastService.setMessage({ text: 'Det oppstod en feil ved bruk av bilde. Prøv igjen.', type: 'error'})
        return throwError(err);
      })
    )
  }

}
