import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { StaticMapComponent } from './components/static-map/static-map.component';
import { RealEstateResultComponent } from './components/real-estate-result/real-estate-result.component';
import { SearchSaleComponent } from './components/search-sale/search-sale.component';
import { SearchUnitComponent } from './components/search-unit/search-unit.component';
import { SimpleTableComponent } from './components/simple-table/simple-table.component';
import { SearchAgentComponent } from './components/search-agent/search-agent.component';
import { SearchEstateComponent } from './components/search-estate/search-estate.component'
import { EvInputReactiveModule } from 'ev-input-reactive';
import { EvLoaderModule } from 'ev-loader';
import { EvDialogModule } from 'ev-dialog';
import { EvTextAreaReactiveModule } from 'ev-textarea-reactive';
import { EvKeyValuePairModule } from 'ev-key-value-pair';
import { EvDropdownReactiveModule } from 'ev-dropdown-reactive';
import { EvMaterialDatepickerModule } from 'ev-material-datepicker';
import { EvCheckboxListComponent } from 'ev-checkbox-list';
import { SearchModalComponent } from './components/search-modal/search-modal.component';

@NgModule({
    declarations: [SideNavComponent, StaticMapComponent, RealEstateResultComponent, SearchSaleComponent, SearchUnitComponent, SimpleTableComponent, SearchAgentComponent, SearchEstateComponent, SearchModalComponent],
    imports: [
        FormsModule,
        CommonModule,
        EvInputReactiveModule,
        EvLoaderModule,
        EvDialogModule,
        EvTextAreaReactiveModule,
        EvKeyValuePairModule,
        EvInputReactiveModule,
        EvDropdownReactiveModule,
        EvMaterialDatepickerModule,
        EvCheckboxListComponent,
        EvLoaderModule,
    ],
    providers: [],
    exports: [
        FormsModule,
        CommonModule,
        SideNavComponent,
        StaticMapComponent,
        RealEstateResultComponent,
        SearchSaleComponent,
        SearchUnitComponent,
        SearchAgentComponent,
        SearchEstateComponent,
        SimpleTableComponent,
        EvDialogModule,
        EvTextAreaReactiveModule,
        EvKeyValuePairModule,
        EvInputReactiveModule,
        EvDropdownReactiveModule,
        EvMaterialDatepickerModule,
        EvCheckboxListComponent,
        EvLoaderModule,
        SearchModalComponent
    ],
})
export class SharedModule {}
