import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { IDropDownItem } from "ev-dropdown-reactive";
import { ITooltip } from "ev-tooltip";
import { WorkQueueItem } from "../../../shared/models/workqueue-item.model";
import { Unit } from "../../../commercial-sale/models/unit.model";
import { iif, Observable, of } from "rxjs";
import { ReferenceTypesService } from "../../../shared/services/reference-types.service";
import { CommercialRentService } from "../../services/commercial-rent.service";
import { WorkQueueService } from "../../../shared/services/workqueue.service";
import { ToastService } from "../../../shared/services/toast.service";
import { WorkQueueSourceTableId } from "../../../shared/models/workqueue-source-table-id.enum";
import { WorkQueueTypeId } from "../../../shared/models/workqueue-type-id.enum";
import { ActivatedRoute, Router } from "@angular/router";
import { Rent, RentWithUnits } from "../../models/rent";
import { Agent } from "../../../shared/models/agent.model";
import { catchError, concatMap, finalize, map, shareReplay, switchMap, tap } from "rxjs/operators";
import { MapMarker } from 'src/app/shared/components/static-map/static-map.component';
import { MapMarkerUtil } from 'src/app/shared/components/static-map/static-map-marker.util';
import { DecimalPipe } from "@angular/common";
import { CheckBoxForm, createFormArray } from 'ev-checkbox-list';

@Component({
    selector: 'commercial-rent-page',
    templateUrl: './commercial-rent-page.component.html',
    styleUrls: ['./commercial-rent-page.component.scss', '../../../../assets/styles/form.scss'],
    standalone: false
})
export class CommercialRentPageComponent implements OnInit {

    numberPipe: DecimalPipe = new DecimalPipe('nb-NO');

    estateForRentId$: Observable<number | null>;
    estateForRentId: number;

    connectedRentUnits: Unit[] = [];
    connectedRentUnitsMapMarkers$: Observable<MapMarker[]>;
    currentEstate$: Observable<RentWithUnits | null>;

    showSearchRentDialog: boolean = false;
    loadingNextWorkqueueItem: boolean = false;
    currentWorkItem: WorkQueueItem = null;
    remainingWorkqueue: number | string = '  ';
    isSaving: Boolean;

    hasElevatorForm = createFormArray([
        { label: '', value: 'hasElevator', checked: false }
    ]);

    hasAdditionalCommonCostForm = createFormArray([
        { value: 'hasAdditionalCommonCost', label: '', checked: false }
    ]);

    hasIncludedElectricityHeatingForm = createFormArray([
        { value: 'hasIncludedElectricityHeating', label: '', checked: false }
    ]);

    commercialRentForm = new UntypedFormGroup({
        sourceTypeId: new UntypedFormControl(null, Validators.required),
        sourceInformationIdentification: new UntypedFormControl(null),
        sourceURL: new UntypedFormControl(null),
        informationDate: new UntypedFormControl(null, Validators.required),
        estateSubTypeId: new UntypedFormControl(null, Validators.required),
        //plotArea: new FormControl(null, Validators.pattern("[0-9]*")),
        totalGrossArea: new UntypedFormControl(null, Validators.pattern("[0-9]*")),
        totalUsableArea: new UntypedFormControl(null, Validators.pattern("[0-9]*")),
        buildYear: new UntypedFormControl(null, Validators.pattern("[0-9]*")),
        renovatedYear: new UntypedFormControl(null, Validators.pattern("[0-9]*")),
        numberOfFloors: new UntypedFormControl(null, Validators.pattern("[0-9]*")),
        parkingTypeId: new UntypedFormControl(null),
        hasElevator: this.hasElevatorForm,
        energyLabel: new UntypedFormControl(null),
        // breeamCertiticateRatingId: new FormControl(null),
        // technicalStandardTypeId: new FormControl(null),
        vacantArea: new UntypedFormControl(null, [Validators.required, Validators.pattern("[0-9]*")]),
        floors: new UntypedFormControl(null),
        monthlyRent: new UntypedFormControl(null, Validators.pattern("[0-9]*")),
        rentPerSqmPerYear: new UntypedFormControl(null, Validators.pattern("[0-9]*")),
        hasAdditionalCommonCost: this.hasAdditionalCommonCostForm,
        hasIncludedElectricityHeating: this.hasIncludedElectricityHeatingForm,
        estateAgentId: new UntypedFormControl(null),
        estateAgentName: new UntypedFormControl(null),
        adText: new UntypedFormControl(null),
        adTextHtml: new UntypedFormControl(null),
    });

    estatesForm = new UntypedFormGroup({
        mapLatitude: new UntypedFormControl(null),
        mapLongitude: new UntypedFormControl(null)
    });

    sourceTypeItems: IDropDownItem[] = [];
    estateSubTypeItems: IDropDownItem[] = [];
    parkingTypeItems: IDropDownItem[] = [];
    breeamCertificateRatingItems: IDropDownItem[] = [];
    technicalStandardTypeItems: IDropDownItem[] = [];

    energyLabelItems: IDropDownItem[] = [
        {
            value: 'A',
            key: 'A',
        },
        {
            value: 'B',
            key: 'B',
        },
        {
            value: 'C',
            key: 'C',
        },
        {
            value: 'D',
            key: 'D',
        },
        {
            value: 'E',
            key: 'E',
        },
        {
            value: 'F',
            key: 'F',
        },
        {
            value: 'G',
            key: 'G',
        },
    ];

    searchSaleTooltip: ITooltip = {
        show: false,
        header: '',
        info: [
            'Her kan du søke frem utleie av næringseiendom på en gitt matrikkel/adresse. Dette er et fritekstsøk der du for eksempel kan søke slik:',
            'Adresse "Storgaten 1 Oslo"',
            'Her kan du skrive kommune eller poststed, evt. også postnummer',
            'Matrikkel "301-234/23/0/7"',
            'Knr-Gnr/Bnr/Fnr/Snr. DU trenger ikke ta med Enr og Snr om disse er 0. Du kan også benytte "mellomrom/space" for å skille paramaterene "Knr Gnr Bnr Fnr Snr". Er du usikker på Knr kan kommunenavn benyttes "Oslo 234/23/0/7".',
            'Alternativt kan du søke på diverse ID-er:',
            'FinnID "f211578326"',
            'EstateID "e1547991"',
            'UnitID "u2210553"',
            'NæringssalgID "i5873"',
        ],
        width: 300
    };

    constructor(
        private referenceTypesService: ReferenceTypesService,
        private commercialRentService: CommercialRentService,
        private workqueueService: WorkQueueService,
        private toastService: ToastService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.estateForRentId$ = this.activatedRoute.params.pipe(map(p => this.parseParams(p)));
        this.estateForRentId$.subscribe(id => this.estateForRentId = id);
        this.currentEstate$ = this.newEstateFromParams();
        this.currentEstate$.subscribe(estate => this.patchEstateWithUnits(estate));
        this.commercialRentService.connectedRentUnits$.subscribe(units => this.connectedRentUnits = units);
        this.connectedRentUnitsMapMarkers$ = commercialRentService.connectedRentUnits$
            .pipe(map(units => { return MapMarkerUtil.convertUnitsToMapMarker(units) }), map(units => units.filter(u => u.lat && u.lng)));
    }

    ngOnInit(): void {
        this.getRemainingWorkQueue();

        this.commercialRentService.setConnectedUnitList([]);

        this.referenceTypesService.getSource().subscribe((source) => {
            this.sourceTypeItems = CommercialRentPageComponent.mapDropdownKeyValues(source);
        });
        this.referenceTypesService.getEstateTypeSubTypes(11).subscribe((estateSub) => {
            this.estateSubTypeItems = CommercialRentPageComponent.mapEstateTypeDropdownKeyValues(estateSub);
        });
        this.referenceTypesService.getParking().subscribe((parking) => {
            this.parkingTypeItems = CommercialRentPageComponent.mapDropdownKeyValues(parking);
        });
        this.referenceTypesService.getBREEAMCertificateRatings().subscribe((breeamCertificateRating) => {
            this.breeamCertificateRatingItems = CommercialRentPageComponent.mapDropdownKeyValues(breeamCertificateRating);
        });
        this.referenceTypesService.getTechnicalStandard().subscribe((technicalStandard) => {
            this.technicalStandardTypeItems = CommercialRentPageComponent.mapDropdownKeyValues(technicalStandard);
        });
    }

    addConnectedRealEstate(unit: Unit) {
        this.commercialRentService.addConnectedUnit(unit);
    }

    deleteConnectedRealEstate(unit: Unit) {
        this.commercialRentService.removeConnectedUnit(unit);
    }

    getNextFromWorkQueue() {
        this.loadingNextWorkqueueItem = true;
        this.workqueueService.getCommonWorkQueueNext(WorkQueueSourceTableId.CommercialEstateRent, WorkQueueTypeId.CommercialRent).subscribe(
            workItem => {
                this.navigateToRent(workItem.sourceTableIdentificationId, workItem)
            },
            error => { this.toastService.setMessage({ text: 'Noe feil har skjedd ved å hente workqueue. Prøv igjen', type: 'error' }) },
            () => { this.loadingNextWorkqueueItem = false; });
    }

    putCommonWorkQueueHold() {
        this.workqueueService.putCommonWorkQueueHold(WorkQueueSourceTableId.CommercialEstateRent, this.currentWorkItem.sourceTableIdentificationId).subscribe(() => {
            this.commercialRentForm.reset();
            this.router.navigate(['commercialrent']);
        });
    }

    saveCurrentRent() {
        this.isSaving = true;
        const commercialRentValue = this.getFormValue(this.commercialRentForm.value);
        if (this.estateForRentId) {
            this.commercialRentService.putRentWithSaleUnits(
                this.estateForRentId,
                commercialRentValue,
                this.commercialRentService.getConnectedUnitsId(),
                this.commercialRentService.getRemovedUnitsId()
            ).pipe(
                concatMap(() => this.setWQProcessed()),
                catchError(errorForFirstOrSecondCall => {
                    this.toastService.setMessage({ text: 'Det oppstod en feil ved lagring: ' + errorForFirstOrSecondCall, type: 'error' });
                    return of({});
                }),
                finalize(() => {
                    this.isSaving = false;
                })
            ).subscribe(() => {
                // TODO: Give user feedback if save succeeded.
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigate(['commercialrent/' + this.estateForRentId]);
            })

        } else {
            const newRent$ = this.commercialRentService.addRent(commercialRentValue).pipe(
                switchMap((response: any) => {
                    this.estateForRentId = response.data.estateForRentId;
                    if (this.commercialRentService.getConnectedUnitsId().length) {
                        return this.commercialRentService.addRentUnits(response.data.estateForRentId, this.commercialRentService.getConnectedUnitsId());
                    }
                    return of({});
                }),
                catchError(errorForFirstOrSecondCall => {
                    this.toastService.setMessage({ text: 'Det oppstod en feil ved lagring: ' + errorForFirstOrSecondCall, type: 'error' });
                    return of({});
                }),
                finalize(() => {
                    this.isSaving = false;
                })
            );

            newRent$.subscribe(() => {
                if (this.estateForRentId != null) {
                    this.router.navigate(['commercialrent/' + this.estateForRentId]);
                }
            }, errorForFirstOrSecondCall => {
                this.toastService.setMessage({ text: 'Det oppstod en feil ved lagring: ' + errorForFirstOrSecondCall, type: 'error' });
            })
        }
    }

    resetRent() {
        this.commercialRentForm.reset();
        this.estatesForm.reset();
        this.commercialRentService.resetConnectedUnits();
        if (this.currentWorkItem != null) {
            this.workqueueService.putCommonWorkQueueReset(WorkQueueSourceTableId.CommercialEstateRent, this.currentWorkItem.sourceTableIdentificationId)
                .subscribe(() => {
                    this.router.navigate(['commercialrent']);
                });
        } else {
            this.router.navigate(['commercialrent']);
        }
    }

    navigateToRent(rentId: number | string, workItem?: WorkQueueItem) {
        this.showSearchRentDialog = false;

        if (workItem != null) {
            this.router.navigate(['commercialrent/' + rentId, workItem]);
        } else {
            this.router.navigate(['commercialrent', rentId]);
        }
    }

    setEstateAgent($event: Agent) {
        this.commercialRentForm.get('estateAgentName')?.patchValue($event.name);
        this.commercialRentForm.get('estateAgentId')?.patchValue($event.id);
    }

    private getFormValue(commercilRentFormValue: any): Rent {
        let formValue = commercilRentFormValue as Rent

        formValue.hasAdditionalCommonCost = this.commercialRentForm.get('hasAdditionalCommonCost')?.value[0].checked;
        formValue.hasElevator = this.commercialRentForm.get('hasElevator')?.value[0].checked;
        formValue.hasIncludedElectricityHeating = this.commercialRentForm.get('hasIncludedElectricityHeating')?.value[0].checked;

        return formValue;
    }

    private getRemainingWorkQueue() {
        this.workqueueService.getCommonWorkQueueRemaining(WorkQueueSourceTableId.CommercialEstateRent).subscribe(q => this.remainingWorkqueue = q);
    }

    private setConnectedSaleUnits(units: Unit[]) {
        this.commercialRentService.setConnectedUnitList(units);
    }

    private newEstateFromParams(): Observable<RentWithUnits> {
        return this.estateForRentId$.pipe(
            switchMap(id => iif(() => id != null, this.commercialRentService.getRentWithUnits(id), of(null))),
            catchError(() => {
                this.toastService.setMessage({ text: 'Noe feil har skjedd ved å hente salg. Prøv igjen', type: 'error' })
                return of(null);
            }),
            tap(rentWithUnits => {
                if (rentWithUnits != null) {
                    this.patchFormControls(rentWithUnits);
                }
            }),
            shareReplay(1));
    }

    /**
   * Helper function to avoid compiler type poblems with form array
   * @param key 
   * @returns the form array
   */
    getFormArray(key: string): FormArray<FormGroup<CheckBoxForm<any>>> {
        return this.commercialRentForm.controls[key] as FormArray<FormGroup<CheckBoxForm<any>>>;
    }

    private parseParams(params: any): number | null {
        const estateRentId = Number.parseInt(params.unitId);

        if (params?.id != null) {
            this.currentWorkItem = params as WorkQueueItem;
        } else {
            this.currentWorkItem = null;
        }

        if (estateRentId) {
            return estateRentId;
        } else {
            return null;
        }
    }

    private patchEstateWithUnits(rentWithUnits: RentWithUnits) {
        if (rentWithUnits != null) {
            //this.commercialRentForm.patchValue(rent);
            this.setConnectedSaleUnits(rentWithUnits?.estateRentUnits);

        } else {
            this.commercialRentForm.reset();
            this.setConnectedSaleUnits([]);
        }
    }

    private patchFormControls(e: RentWithUnits) {
        this.commercialRentForm.controls['adTextHtml'].patchValue(e.adTextHtml);
        //this.commercialRentForm.controls['adText'].patchValue(e.adText);
        this.commercialRentForm.controls['buildYear'].patchValue(e.buildYear);
        this.commercialRentForm.controls['energyLabel'].patchValue(e.energyLabel);
        this.commercialRentForm.controls['estateAgentId'].patchValue(e.estateAgentId);
        this.commercialRentForm.controls['estateAgentName'].patchValue(e.estateAgentName);
        this.commercialRentForm.controls['estateSubTypeId'].patchValue(e.estateSubTypeId);
        this.commercialRentForm.controls['floors'].patchValue(e.floors);
        this.commercialRentForm.controls['hasAdditionalCommonCost'] = createFormArray([{ label: '', value: 'hasElevator', checked: e.hasAdditionalCommonCost }]);
        this.commercialRentForm.controls['hasElevator'] = createFormArray([{ label: '', value: 'hasElevator', checked: e.hasElevator }]);
        this.commercialRentForm.controls['hasIncludedElectricityHeating'] = createFormArray([{ label: '', value: 'hasElevator', checked: e.hasIncludedElectricityHeating }]);
        this.commercialRentForm.controls['informationDate'].patchValue(e.informationDate);
        this.commercialRentForm.controls['monthlyRent'].patchValue(e.monthlyRent);
        this.commercialRentForm.controls['numberOfFloors'].patchValue(e.numberOfFloors);
        this.commercialRentForm.controls['parkingTypeId'].patchValue(e.parkingTypeId);
        this.commercialRentForm.controls['rentPerSqmPerYear'].patchValue(e.rentPerSqmPerYear);
        this.commercialRentForm.controls['renovatedYear'].patchValue(e.renovatedYear);
        this.commercialRentForm.controls['sourceInformationIdentification'].patchValue(e.sourceInformationIdentification);
        this.commercialRentForm.controls['sourceTypeId'].patchValue(e.sourceTypeId);
        this.commercialRentForm.controls['sourceURL'].patchValue(e.sourceURL);
        this.commercialRentForm.controls['totalGrossArea'].patchValue(e.totalGrossArea);
        this.commercialRentForm.controls['totalUsableArea'].patchValue(e.totalUsableArea);
        this.commercialRentForm.controls['vacantArea'].patchValue(e.vacantArea);
        //this.commercialRentForm.controls['breeamCertiticateRatingId'].patchValue(e.breeamCertiticateRatingId);
        //this.commercialRentForm.controls['technicalStandardTypeId'].patchValue(e.technicalStandardTypeId);
        //this.commercialRentForm.controls['plotArea'].patchValue(e.plotArea);
    }

    private static mapDropdownKeyValues(dropdownItems: { id: any; name: any; }[]) {
        return dropdownItems.map(({ id, name }) => ({ key: id, value: name }));
    }

    private static mapEstateTypeDropdownKeyValues(dropdownItems: { id: any; name: any; subName: any, subId: any }[]) {
        return dropdownItems.map(({ subId, subName }) => ({ key: subId, value: subName }));
    }

    private setWQProcessed(): Observable<any> {
        if (this.currentWorkItem != null && this.currentWorkItem.sourceTableIdentificationId != null) {
            return this.workqueueService.putCommonWorkQueueProcessed(WorkQueueSourceTableId.CommercialEstateRent, this.currentWorkItem.sourceTableIdentificationId);
        } else {
            return of(1);
        }
    }
}
