import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, LOCALE_ID, ErrorHandler} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";
import { registerLocaleData } from '@angular/common';
import localeNb from '@angular/common/locales/nb';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CommercialSaleModule } from './commercial-sale/commercial-sale.module';
import { SharedModule } from "./shared/shared.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { OrganizationSubUnitsModule } from "./organization-sub-units/organization-sub-units.module";
import { CommercialRentModule } from "./commercial-rent/commercial-rent.module";
import { PhotoRegistrationModule } from "./photo-registration/photo-registration.module";
import { EstateTypeCorrectionModule } from "./estate-type-correction/estate-type-correction.module";
import { ApplicationInsightsErrorHandler } from './utils/ApplicationInsightsErrorHandler';
import { EvToastModule } from "ev-toast";
import { environment } from "../environments/environment";

import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from "@azure/msal-angular";
import { InteractionType, PublicClientApplication, BrowserCacheLocation } from "@azure/msal-browser";

registerLocaleData(localeNb);

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        CommercialSaleModule,
        CommercialRentModule,
        PhotoRegistrationModule,
        EstateTypeCorrectionModule,
        DashboardModule,
        OrganizationSubUnitsModule,
        SharedModule,
        MsalModule.forRoot(new PublicClientApplication({
            auth: environment.auth,
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: false, // Set to true for Internet Explorer 11
            },
        }), {
            interactionType: InteractionType.Redirect, // MSAL Guard Configuration
            authRequest: { scopes: [environment.apiAccessScope] },
        }, {
            interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
            protectedResourceMap: new Map([
                [environment.apiUrl, [environment.apiAccessScope]]
            ])
        }),
        EvToastModule], providers: [
        { provide: LOCALE_ID, useValue: 'nb' },
        { provide: ErrorHandler, useClass: ApplicationInsightsErrorHandler },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        MsalGuard,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
