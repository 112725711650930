import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { CommercialSalePageComponent } from './commercial-sale/pages/commercial-sale-page/commercial-sale-page.component';
import { DashboardPageComponent } from './dashboard/pages/dashboard-page/dashboard-page.component';
import { OrganizationSubUnitsPageComponent } from './organization-sub-units/pages/organization-sub-units-page/organization-sub-units-page.component';
import { CommercialRentPageComponent } from './commercial-rent/pages/commercial-rent-page/commercial-rent-page.component';
import { PhotoRegistrationPageComponent } from './photo-registration/pages/photo-registration-page/photo-registration-page.component';
import { EstateTypeCorrectionPageComponent } from './estate-type-correction/pages/estate-type-correction-page/estate-type-correction-page.component';
import { EnergyLabelsComponent } from './energy-labels/energy-labels.component';

const routes: Routes = [
    {
        path: '',
        component: DashboardPageComponent,
        canActivate: [MsalGuard],
        title: 'Dashboard',
        pathMatch: 'full',
    },
    {
        path: 'commercialsale',
        component: CommercialSalePageComponent,
        title: 'Næringssalg',
        canActivate: [MsalGuard],
    },
    {
        path: 'commercialsale/:estateId',
        component: CommercialSalePageComponent,
        title: 'Næringssalg',
        canActivate: [MsalGuard],
    },
    {
        path: 'organizationsubunits',
        component: OrganizationSubUnitsPageComponent,
        title: 'Enhetsregisteret',
        canActivate: [MsalGuard],
    },
    {
        path: 'organizationsubunits/:orgUnitId',
        component: OrganizationSubUnitsPageComponent,
        title: 'Enhetsregisteret',
        canActivate: [MsalGuard],
    },
    {
        path: 'commercialrent',
        component: CommercialRentPageComponent,
        title: 'Utleie av næringseiendom',
        canActivate: [MsalGuard],
    },
    {
        path: 'commercialrent/:unitId',
        component: CommercialRentPageComponent,
        title: 'Utleie av næringseiendom',
        canActivate: [MsalGuard],
    },
    {
        path: 'photoregistration',
        component: PhotoRegistrationPageComponent,
        title: 'Registrering av foto',
        canActivate: [MsalGuard],
    },
    {
        path: 'estatetypecorrection',
        component: EstateTypeCorrectionPageComponent,
        title: 'Korrigering eiendomstype',
        canActivate: [MsalGuard],
    },
    {
        path: 'estatetypecorrection/:unitId',
        component: EstateTypeCorrectionPageComponent,
        title: 'Korrigering eiendomstype',
        canActivate: [MsalGuard],
    },
    {
        path: 'energylabels',
        component: EnergyLabelsComponent,
        title: 'Energimerker',
        canActivate: [MsalGuard],
    },
    {
        path: 'energylabels/:energyLabelInfoID',
        component: EnergyLabelsComponent,
        title: 'Energimerker',
        canActivate: [MsalGuard],
    },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
