"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.doTemplate = void 0;
exports.doTemplate = function (string, data) {
  var value;
  for (var key in data) {
    if (data.hasOwnProperty(key)) {
      value = data[key];
      string = string.replace('{' + key + '}', value);
    }
  }
  return string;
};