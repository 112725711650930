"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAttributionText = void 0;
var bounds_1 = require("./constants/bounds");
// Used for setting copyrights
var currentYear = new Date().getFullYear();
exports.getAttributionText = function (centerPoint, zoomLevel) {
  var copyrightText = "&copy; " + currentYear + " Norkart AS";
  var t_url = [copyrightText + "/Plan- og bygningsetaten, Oslo Kommune", copyrightText + "/Geovekst og kommunene/OpenStreetMap/NASA, Meti", copyrightText + "/Geovekst og kommunene/OpenStreetMap/NASA, Meti", copyrightText + "/OpenStreetMap/EEA CLC2006"];
  //Filter based on zoom levels and area
  if (zoomLevel >= 13) {
    if (zoomLevel <= 14) {
      //Level 13 & 14
      try {
        if (containsPoint(centerPoint, bounds_1.norgeLat, bounds_1.norgeLon)) {
          //Rest of Norway, Oslo has no special data at this level
          return t_url[1];
        }
      } catch (e) {}
    } else {
      try {
        if (containsPoint(centerPoint, bounds_1.osloLat, bounds_1.osloLon)) {
          // Here we give special consideration to Oslo
          return t_url[0];
        }
      } catch (e) {}
      try {
        if (containsPoint(centerPoint, bounds_1.norgeLat, bounds_1.norgeLon)) {
          //Rest of Norway
          return t_url[1];
        }
      } catch (e) {}
    }
    // If it's not either Oslo or Norway, it's the rest of Europe
    return t_url[3];
  } else {
    //Level 1-12, either Norway zoomed out or the rest of Europe
    try {
      if (containsPoint(centerPoint, bounds_1.norgeLat, bounds_1.norgeLon)) {
        return t_url[2];
      } else {
        return t_url[3];
      }
    } catch (e) {}
  }
  return copyrightText;
};
var containsPoint = function (centerPoint, kommuneLat, kommuneLon) {
  var j = 0;
  var t_polySides = kommuneLat.length;
  var t_oddNodes = false;
  for (var i = 0; i < t_polySides; i++) {
    j++;
    if (j === t_polySides) {
      j = 0;
    }
    if (kommuneLon[i] < centerPoint.lng && kommuneLon[j] >= centerPoint.lng || kommuneLon[j] < centerPoint.lng && kommuneLon[i] >= centerPoint.lng) {
      if (kommuneLat[i] + (centerPoint.lng - kommuneLon[i]) / (kommuneLon[j] - kommuneLon[i]) * (kommuneLat[j] - kommuneLat[i]) < centerPoint.lat) {
        t_oddNodes = !t_oddNodes;
      }
    }
  }
  return t_oddNodes;
};