import { Component, OnInit } from '@angular/core';
import { WorkQueueService } from "../../../shared/services/workqueue.service";
import { ActivatedRoute, Router } from "@angular/router";
import { WorkQueueItem } from "../../../shared/models/workqueue-item.model";
import { WorkQueueSourceTableId } from "../../../shared/models/workqueue-source-table-id.enum";
import { ToastService } from "../../../shared/services/toast.service";
import { WorkQueueTypeId } from "../../../shared/models/workqueue-type-id.enum";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { IDropDownItem } from "ev-dropdown-reactive";
import { ReferenceTypesService } from "../../../shared/services/reference-types.service";
import { EstatesService } from "../../../commercial-sale/services/estates.service";
import { Unit } from "../../../commercial-sale/models/unit.model";
import { PublicOwner } from "../../models/publicOwner.model";
import { OrganizationUnit } from "../../models/organizationUnit.model";
import { PublicBuilding } from "../../models/publicBuilding.model";
import { Header } from "../../../shared/components/simple-table/simple-table.component";
import { environment } from "../../../../environments/environment";
import { concatMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { EstateTypeSubType } from 'src/app/shared/models/estateTypeSubType';

@Component({
    selector: 'estate-type-correction-page',
    templateUrl: './estate-type-correction-page.component.html',
    styleUrls: ['./estate-type-correction-page.component.scss', '../../../../assets/styles/toolkit.scss'],
    standalone: false
})
export class EstateTypeCorrectionPageComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private workQueueService: WorkQueueService,
    private estateService: EstatesService,
    private toastService: ToastService,
    private referenceTypes: ReferenceTypesService,
    private router: Router
  ) { }

  estateSubTypeItems: IDropDownItem[] = [];
  estateTypeItems: IDropDownItem[] = [];
  estateTypeSubTypes: EstateTypeSubType[] = [];


  loadingNextWorkQueueItem: boolean = false;
  remainingWorkQueue: number | string = '  ';
  currentWorkItem: WorkQueueItem | null = null;
  estateId: string = '';
  address: string = "";

  estateTypeCorrectionForm = new UntypedFormGroup({
    estateSubTypeId: new UntypedFormControl(null),
    estateSubTypeComment: new UntypedFormControl('', Validators.required),
    estateTypeId: new UntypedFormControl(null)
  });

  estateSubTypeId = {get: this.estateTypeCorrectionForm.get('estateSubTypeId')}

  estateResult: Unit[];
  loadingEstatesSearch: boolean;
  publicBuildingsTableHeader: Header[] = [
    {title: 'Bygningstype', key: 'bygningstype', textAlign: 'left'},
    {title: 'Totalt BRA', key: 'bruksarealTotalt', textAlign: 'right'},
    {title: 'BRA bolig', key: 'bruksarealBolig', textAlign: 'right'},
    {title: 'BRA Annet', key: 'bruksarealAnnatBolig', textAlign: 'right'},
    {title: 'Antall boliger', key: 'antalBoliger', textAlign: 'right'},
    {title: 'Næringsgruppe', key: 'naeringsgruppe', textAlign: 'left'},
    {title: 'Bygningsstatus', key: 'bygningsstatus', textAlign: 'left'}
  ]

  publicOwnersTableHeader: Header[] = [
    {title: 'Navn', key: 'name', textAlign: 'left'},
    {title: 'Eierandel', key: 'shareNumerator', textAlign: 'right'},
    {title: 'Type', key: 'ownertype', textAlign: 'left'}
  ]

  organizationUnitTableHeader: Header[] = [
    {title: 'Navn', key: 'organizationName', textAlign: 'left'},
    {title: 'Ansatte', key: 'numEmployees', textAlign: 'right'},
    {title: 'Næringskode NS 2007', key: 'institutionalSectorTypeDescription', textAlign: 'left'}
  ]

  publicOwners: PublicOwner[];
  publicBuildings: PublicBuilding[];
  organizationUnits: OrganizationUnit[];
  showSearchUnitDialog: boolean = false;

  private unitId: string;
  saved: any;

  ngOnInit(): void {
    this.referenceTypes.getEstateTypeSubTypes().subscribe((estateTypeSubType) => {
      this.estateTypeSubTypes = estateTypeSubType;
      this.estateTypeItems = this.mapEstateTypeDropdownItems(estateTypeSubType);
    });

    this.setupEstateTypeValuechanges();

    this.activatedRoute.params.subscribe( params => {
      this.getRemainingWorkQueue();
      if (params?.id != null) {
        this.currentWorkItem = params as WorkQueueItem;
      } else {
        this.address = params?.fulladdress;
        this.currentWorkItem = null;
      }

      this.unitId = params['unitId'];

      if(this.unitId) {
        this.loadingNextWorkQueueItem = true;

        this.estateService.getEstateSubType(this.unitId).subscribe(estateSubType => {
          const estateTypeId = Math.floor(estateSubType.estateSubTypeId / 100);

          this.estateTypeCorrectionForm.patchValue(
            {estateSubTypeId: estateSubType.estateSubTypeId, 
              estateTypeId: estateTypeId,
              estateSubTypeComment: estateSubType.estateSubTypeComment});

          this.mapSubTypeDropdownItems(estateTypeId);
        });

        this.estateService.getEstatePublicOwners(this.unitId).subscribe(publicOwners => this.publicOwners = publicOwners);
        this.estateService.getEstatePublicBuildings(this.unitId).subscribe(publicBuildings => {
          this.publicBuildings = this.filterBuildingTypes(publicBuildings);
          this.estateId = publicBuildings && publicBuildings.length > 0 ? publicBuildings[0].estateId.toString() : '';
        });
        this.estateService.getEstateOrganizationUnits(this.unitId).subscribe(organizationUnits => this.organizationUnits = organizationUnits);
        this.loadingNextWorkQueueItem = false;
      }
    })
  }

  navigateToUnit(unit : Unit) {
    this.router.navigate(['estatetypecorrection/' + unit.unitId, {fulladdress : unit.fullAddress} ]);
  }

  navigateToWorkItem(unitId: number | string, workItem? : WorkQueueItem) {
    this.showSearchUnitDialog = false;

    if(workItem != null) {
      this.router.navigate(['estatetypecorrection/'+ unitId, workItem]);
    } else {
      this.router.navigate(['estatetypecorrection/', unitId]);
    }
  }

  getNextFromWorkQueue() {
    this.address = "";
    this.loadingNextWorkQueueItem = true;
    this.resetState();
    this.workQueueService.getCommonWorkQueueNext(WorkQueueSourceTableId.EstateTypeCorrection, WorkQueueTypeId.EstateTypeCorrection).subscribe(
      workItem => {
        this.navigateToWorkItem(workItem.sourceTableIdentificationId, workItem)},
      error => {this.toastService.setMessage({text: 'Noe feil har skjedd ved å hente workqueue. Prøv igjen', type: 'error'})},
      () => {this.loadingNextWorkQueueItem = false;});
  }

  putCommonWorkQueueHold() {
    this.workQueueService.putCommonWorkQueueHold(WorkQueueSourceTableId.EstateTypeCorrection, this.currentWorkItem.sourceTableIdentificationId).subscribe(() => {
      this.estateTypeCorrectionForm.reset();
      this.router.navigate(['estatetypecorrection']);
    });
  }

  searchEstates(searchInput: string) {
    if(searchInput !== '' && searchInput != null && !this.loadingEstatesSearch) {
      this.loadingEstatesSearch = true;

      this.estateService.searchEstates(searchInput).subscribe(
        (response) => {
          this.loadingEstatesSearch = false;
          this.estateResult = response;
        },
        (error) => {
          this.loadingEstatesSearch = false;
          this.toastService.setMessage({ text: 'Skjedd en feil søk på address. Prøv på nytt', type: 'error'});
        })
    }
  }

  saveEstateTypeCorrection() {
    if(this.estateSubTypeId != null) {
      this.estateService.updateEstateSubType(this.unitId, this.estateTypeCorrectionForm.value)
      .pipe(concatMap(() => this.setWQProcessed()))
      .subscribe(res => {
        this.saved = true;
        if (this.address != "") {
          this.router.navigate(['estatetypecorrection/' + this.unitId, {fulladdress : this.address} ]);
        } else {
          this.router.navigate(['estatetypecorrection/' + this.unitId, {fulladdress : this.currentWorkItem?.info} ]);
        }
      },error => {this.toastService.setMessage({text: 'Noe feil har skjedd ved å lagre. Prøv igjen', type: 'error'})})
    }
  }

  cancel() {
    if(this.currentWorkItem != null && this.currentWorkItem.sourceTableIdentificationId) {
      this.workQueueService.putCommonWorkQueueReset(WorkQueueSourceTableId.EstateTypeCorrection, this.currentWorkItem.sourceTableIdentificationId)
        .subscribe(() => {
          this.router.navigate(['estatetypecorrection']);
        });
    } else {
      this.resetState();
      this.router.navigate(['estatetypecorrection']);
    }
  }

  goToLink() {
    window.open(`${environment.portalApi}/ManualValuation/Home#/infopage/commercial;estateId=${this.estateId}`, '_blank');
  }

  private filterBuildingTypes(publicBuildings: PublicBuilding[]): PublicBuilding[] {
    const filterTypes: string[] = ["Bygging avlyst", "Bygning flyttet", "Bygning revet/brent", "Bygningsnr utgått"];

    const filteredList = publicBuildings?.filter(b => filterTypes.indexOf(b.bygningsstatus) === -1);

    return filteredList;
  }

  private setupEstateTypeValuechanges() {
    this.estateTypeCorrectionForm.get('estateTypeId').valueChanges.subscribe(c => this.mapSubTypeDropdownItems(c));
  }


  private resetState() {
    this.publicOwners = [];
    this.publicBuildings =  [];
    this.organizationUnits = [];
    this.showSearchUnitDialog = false;
    this.estateResult = null;
    this.saved = false;
    this.estateId = '';
    this.estateTypeCorrectionForm.reset();
    this.estateSubTypeItems = [];
  }

  private mapSubTypeDropdownItems(estateTypeId: number): IDropDownItem[]{
    const subtypes =  this.estateTypeSubTypes.filter(e => e.id === estateTypeId).map(({subId, subName}) => ({key: subId, value: subName}));
    this.estateSubTypeItems = subtypes;
    return subtypes;
  }

  private mapEstateTypeDropdownItems(estateTypes: EstateTypeSubType[]): IDropDownItem[] {
    let unique: EstateTypeSubType[] = [];

    //assumes that estateTypes are sorted;
    let previous: number = -1;
    for(let item of estateTypes) {
      if(previous != item.id) {
        unique.push(item);
        previous = item.id;
      }
    }

    return unique.map(({id, name}) => ({key: id, value: name}));
  }

  private getRemainingWorkQueue() {
    this.workQueueService.getCommonWorkQueueRemaining(WorkQueueSourceTableId.EstateTypeCorrection).subscribe(queue => this.remainingWorkQueue = queue);
  }

  private setWQProcessed(): Observable<any> {
    if(this.currentWorkItem != null && this.currentWorkItem.sourceTableIdentificationId != null) {
      return this.workQueueService.putCommonWorkQueueProcessed(WorkQueueSourceTableId.EstateTypeCorrection, this.currentWorkItem.sourceTableIdentificationId);
    } else {
      return of(1);
    }
  }
}
