import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

export interface VersionObject {
  version: number;
  isApprovedForPublish: boolean;
}

@Component({
    selector: 'versions',
    templateUrl: './versions.component.html',
    styleUrls: ['./versions.component.scss'],
    standalone: false
})
export class VersionsComponent {

  @Input() versions: VersionObject[];
  @Input("selected") inputSelected: number;
  
  @Output() changedSelected = new EventEmitter<number>();
  
  selected: number; 

  ngOnInit(){
    this.selected = this.inputSelected;
  }
  
  ngOnChanges(changes: SimpleChanges){
    if (changes?.inputSelected?.currentValue != null) {
      this.selected = changes.inputSelected.currentValue;
    }
  }

  versionsChange(version: number){
    this.selected = version;
    this.changedSelected.emit(version);
  }
}