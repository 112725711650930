<div class="commercial-sale-wrapper">
    <div class="sale-input-wrapper">
        <div class="button-collection">
            <div class="row">
                <div class="left">
                    <button (click)="resetSale()">Nytt salg</button>
                    <button (click)="showSearchSaleDialog = true">Søk</button>
                    <button class="workqueue" (click)="getNextFromWorkQueue()" [attr.disabled]="currentWorkItem != null || loadingNextWorkqueueItem ? true : null">
                        {{ "Neste fra Workqueue (" + remainingWorkqueue + ")" }}
                    </button>
                </div>
                <div class="right">
                    <button (click)="putCommonWorkQueueHold()">Sett til ekspert</button>
                </div>
            </div>
        
            <div class="row">
                <div class="workqueue">
                    <div class="title">{{ currentWorkItem?.info }}</div>
                    <div class="instruction">{{ currentWorkItem?.instruction }}</div>
                </div>
            </div>
        </div>
        
        <div class="commercial-sale">
            <div class="commercial-sale__row first">
                <div class="form__row">
                    <div class="form__column">
                        <div class="validation entry important mandatory">
                            <div class="underlined">
                                <div class="blue-bar"></div>
                                <ev-key-value-pair [key]="'Kilde'" [borderless]="true">
                                    <ev-dropdown-reactive *ngIf="sourceTypeItems.length" [height]="'40px'" [items]="sourceTypeItems" [parentForm]="commercialSaleForm" [formControlNameInput]="'sourceTypeId'">
                                    </ev-dropdown-reactive>
                                </ev-key-value-pair>
                            </div>
                            <div *ngIf="commercialSaleForm.get('sourceTypeId').invalid" class="error icon"></div>
                        </div>
                    </div>
                    <div class="form__column">
                        <div class="entry">
                            <ev-key-value-pair [key]="'KildeID'" [variant]="'grey'">
                                <ev-input-reactive [type]="'number'" [hideReset]="true" [formCtrl]="commercialSaleForm.get('sourceInformationIdentification')"></ev-input-reactive>
                            </ev-key-value-pair>
                        </div>
                    </div>
                    <div class="form__column"></div>
                </div>
                <div class="form__row">
                    <div class="form__column2">
                        <div class="url-link entry">
                            <ev-key-value-pair [key]="'URL'" [variant]="'grey'" [width]="'50px'">
                                <ev-input-reactive [type]="'text'" [hideReset]="true" [formCtrl]="commercialSaleForm.get('sourceURL')" ></ev-input-reactive>
                            </ev-key-value-pair>
                            <a [href]="this.commercialSaleForm.get('sourceURL').value" target="_punch-sale">Åpne</a>
                        </div>
                    </div>
                    <div class="form__column"></div>
                    <div class="versions-row">
                        <div class="entry version-overview">
                            <div class="title">
                                Versjoner
                            </div>
                            <div class="versions">
                                <versions [selected]="selected" [versions]="versions" (changedSelected)="changedSelected($event)"></versions>
                            </div>
                        </div>
                        <div class="validation entry important mandatory">
                            <div class="underlined">
                                <div class="blue-bar"></div>
                                <ev-key-value-pair [key]="'Dato for annonse'" [borderless]="true">
                                    <ev-material-datepicker [lineHeight]="'40px'" [formControlNameInput]="'informationDate'" [parentForm]="commercialSaleForm" [isRegular]="true"></ev-material-datepicker>
                                </ev-key-value-pair>
                            </div>
                            <div *ngIf="commercialSaleForm.get('informationDate').invalid" class="error icon"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="commercial-sale__row">
                <div class="form__row">
                    <div class="form__column">
                        <div class="validation entry important mandatory">
                            <div class="underlined">
                                <div class="blue-bar"></div>
                                <ev-key-value-pair [key]="'Eiendomstype'" [borderless]="true">
                                    <ev-dropdown-reactive *ngIf="estateSubTypeItems.length" [height]="'40px'" [items]="estateSubTypeItems" [parentForm]="commercialSaleForm" [formControlNameInput]="'estateSubTypeId'">
                                    </ev-dropdown-reactive>
                                </ev-key-value-pair>
                            </div>
                            <div *ngIf="commercialSaleForm.get('estateSubTypeId').invalid" class="error icon"></div>
                        </div>
                        <div class="validation entry important mandatory">
                            <div class="underlined">
                                <div class="blue-bar"></div>
                                <ev-key-value-pair [key]="'Totalt BTA'" [borderless]="true">
                                    <ev-input-reactive
                                        [height]="40"
                                        [type]="'number'"
                                        [hideReset]="true"
                                        [formCtrl]="commercialSaleForm.get('totalGrossArea')"
                                        [pipeTransform]="numberPipe"
                                    ></ev-input-reactive>
                                </ev-key-value-pair>
                            </div>
                            <div *ngIf="commercialSaleForm.errors?.['emptyBTAandBRA']" class="error icon"></div>
                        </div>
                        <div class="validation entry important mandatory">
                            <div class="underlined">
                                <div class="blue-bar"></div>
                                <ev-key-value-pair [key]="'Totalt BRA'" [borderless]="true">
                                    <ev-input-reactive
                                        [height]="40"
                                        [type]="'number'"
                                        [hideReset]="true"
                                        [formCtrl]="commercialSaleForm.get('totalUsableArea')"
                                        [pipeTransform]="numberPipe"
                                    ></ev-input-reactive>
                                </ev-key-value-pair>
                            </div>
                            <div *ngIf="commercialSaleForm.errors?.['emptyBTAandBRA']" class="error icon"></div>
                        </div>
                        <div class="validation entry">
                            <ev-key-value-pair [key]="'Byggeår'" [variant]="'grey'">
                                <ev-input-reactive [type]="'number'" [hideReset]="true" [formCtrl]="commercialSaleForm.get('buildYear')"  [pipeTransform]="numberPipe"></ev-input-reactive>
                            </ev-key-value-pair>
                            <div *ngIf="commercialSaleForm.get('buildYear').invalid" class="error icon"></div>
                        </div>
                        <div class="validation entry">
                            <ev-key-value-pair [key]="'Renovert år'" [variant]="'grey'">
                                <ev-input-reactive
                                    [type]="'number'"
                                    [hideReset]="true"
                                    [formCtrl]="commercialSaleForm.get('renovatedYear')"
                                    [pipeTransform]="numberPipe"
                                ></ev-input-reactive>
                            </ev-key-value-pair>
                            <div *ngIf="commercialSaleForm.get('renovatedYear').invalid" class="error icon"></div>
                        </div>
                        <div class="validation entry">
                            <ev-key-value-pair [key]="'Antall etasjer'" [variant]="'grey'">
                                <ev-input-reactive
                                    [type]="'number'"
                                    [hideReset]="true"
                                    [formCtrl]="commercialSaleForm.get('numberOfFloors')"
                                    [pipeTransform]="numberPipe"
                                ></ev-input-reactive>
                            </ev-key-value-pair>
                            <div *ngIf="commercialSaleForm.get('numberOfFloors').invalid" class="error icon"></div>
                        </div>
                        <div class="entry">
                            <ev-key-value-pair [key]="'Megler/selger'" [variant]="'grey'">
                                <ev-input-reactive
                                    [type]="'text'"
                                    [hideReset]="true"
                                    [formCtrl]="commercialSaleForm.get('estateAgentName')"                         
                                ></ev-input-reactive>
                                <search-agent (selectedAgentEvent)="setEstateAgent($event)" title="Søk etter megler"></search-agent>
                            </ev-key-value-pair>
                        </div>
                        <div class="entry important">
                            <div class="underlined">
                                <ev-key-value-pair [key]="'Parkering'" [borderless]="true">
                                    <ev-dropdown-reactive *ngIf="parkingTypeItems.length"  [height]="'40px'" [items]="parkingTypeItems" [parentForm]="commercialSaleForm" [formControlNameInput]="'parkingTypeId'">
                                    </ev-dropdown-reactive>
                                </ev-key-value-pair>
                            </div>
                        </div>
                        <div class="entry important">
                                <div class="underlined">
                                <ev-key-value-pair [key]="'Heis'" [borderless]="true">
                                    <ev-checkbox-list [parentForm]="getElevatorFormArray()"></ev-checkbox-list>
                                </ev-key-value-pair>
                            </div>
                        </div>
                        <div class="entry">
                            <ev-key-value-pair [key]="'Energimerke'" [variant]="'grey'">
                                <ev-dropdown-reactive *ngIf="energyLabelItems.length"  [items]="energyLabelItems" [parentForm]="commercialSaleForm" [formControlNameInput]="'energyLabel'">
                                </ev-dropdown-reactive>
                            </ev-key-value-pair>
                        </div>
                        <!-- <div>
                  <ev-key-value-pair [key]="'BREEAM sertifisering'">
                    <ev-dropdown-reactive *ngIf="breeamCertificateRatingItems.length"  [items]="breeamCertificateRatingItems" [parentForm]="commercialSaleForm" [formCtrl]="'breeamCertiticateRatingId'">
                    </ev-dropdown-reactive>
                  </ev-key-value-pair>
                </div>
                <div>
                  <ev-key-value-pair [key]="'TEK Standard'">
                    <ev-dropdown-reactive *ngIf="technicalStandardTypeItems.length" [items]="technicalStandardTypeItems" [parentForm]="commercialSaleForm" [formCtrl]="'technicalStandardTypeId'">
                    </ev-dropdown-reactive>
                  </ev-key-value-pair>
                </div> -->
                    </div>
                    <div class="form__column">
                        <div class="validation entry">
                            <ev-key-value-pair [key]="'Prisantydning'" [variant]="'grey'">
                                <ev-input-reactive
                                    [type]="'number'"
                                    [hideReset]="true"
                                    [formCtrl]="commercialSaleForm.get('askingPrice')"
                                    [pipeTransform]="numberPipe"
                                ></ev-input-reactive>
                            </ev-key-value-pair>
                            <div *ngIf="commercialSaleForm.get('askingPrice').invalid" class="error icon"></div>
                        </div>
                        <div class="validation entry">
                            <ev-key-value-pair [key]="'Takst'" [variant]="'grey'">
                                <ev-input-reactive
                                    [type]="'number'"
                                    [hideReset]="true"
                                    [formCtrl]="commercialSaleForm.get('surveyorAppraisal')"
                                    [pipeTransform]="numberPipe"
                                ></ev-input-reactive>
                            </ev-key-value-pair>
                            <div *ngIf="commercialSaleForm.get('surveyorAppraisal').invalid" class="error icon"></div>
                        </div>
                        <div class="entry">
                            <ev-key-value-pair [key]="'Takstdato'" [variant]="'grey'">
                                <ev-material-datepicker [formControlNameInput]="'surveyorAppraisalDate'" [parentForm]="commercialSaleForm" [isRegular]="true"></ev-material-datepicker>
                            </ev-key-value-pair>
                        </div>
                        <div class="validation entry">
                            <ev-key-value-pair [key]="'Formuesverdi'" [variant]="'grey'">
                                <ev-input-reactive
                                    [type]="'number'"
                                    [hideReset]="true"
                                    [formCtrl]="commercialSaleForm.get('propertyValue')"
                                    [pipeTransform]="numberPipe"
                                ></ev-input-reactive>
                            </ev-key-value-pair>
                            <div *ngIf="commercialSaleForm.get('propertyValue').invalid" class="error icon"></div>
                        </div>
                        <div class="validation entry">
                            <ev-key-value-pair [key]="'Festeavgift'" [variant]="'grey'">
                                <ev-input-reactive [type]="'number'" [hideReset]="true" [formCtrl]="commercialSaleForm.get('plotLease')" [pipeTransform]="numberPipe"></ev-input-reactive>
                            </ev-key-value-pair>
                            <div *ngIf="commercialSaleForm.get('plotLease').invalid" class="error icon"></div>
                        </div>
                        <div class="entry">
                            <ev-key-value-pair [key]="'Utløp festekontrakt'" [variant]="'grey'">
                                <ev-material-datepicker [formControlNameInput]="'plotLeaseContractExpireDate'" [parentForm]="commercialSaleForm" [isRegular]="true"></ev-material-datepicker>
                            </ev-key-value-pair>
                        </div>
                        <div class="validation entry">
                            <ev-key-value-pair [key]="'Kommunale avgifter'" [variant]="'grey'">
                                <ev-input-reactive
                                    [type]="'number'"
                                    [hideReset]="true"
                                    [formCtrl]="commercialSaleForm.get('municipalTaxes')"
                                    [pipeTransform]="numberPipe"
                                ></ev-input-reactive>
                            </ev-key-value-pair>
                            <div *ngIf="commercialSaleForm.get('municipalTaxes').invalid" class="error icon"></div>
                        </div>
                        <div class="validation entry">
                            <ev-key-value-pair [key]="'Leieinntekt per år'" [variant]="'grey'">
                                <ev-input-reactive
                                    [type]="'number'"
                                    [hideReset]="true"
                                    [formCtrl]="commercialSaleForm.get('rentalIncomePerYear')"
                                    [pipeTransform]="numberPipe"
                                ></ev-input-reactive>
                            </ev-key-value-pair>
                            <div *ngIf="commercialSaleForm.get('rentalIncomePerYear').invalid" class="error icon"></div>
                        </div>
                        <div class="entry important">
                            <div class="underlined">
                                <ev-key-value-pair [key]="'Utleiestatus'" [borderless]="true">
                                    <ev-dropdown-reactive *ngIf="rentalStatusItems.length" [height]="'40px'" [items]="rentalStatusItems" [parentForm]="commercialSaleForm" [formControlNameInput]="'rentalStatusId'">
                                    </ev-dropdown-reactive>
                                </ev-key-value-pair>
                            </div>
                        </div>
                        <div class="validation entry important">
                            <div class="underlined">
                                <ev-key-value-pair [key]="'Utleie andel'" [borderless]="true">
                                    <ev-input-reactive
                                        [height]="40"
                                        [type]="'number'"
                                        [hideReset]="true"
                                        [formCtrl]="commercialSaleForm.get('rentalShare')"
                                        [pipeTransform]="numberPipe"
                                    ></ev-input-reactive>
                                </ev-key-value-pair>
                            </div>
                            <div *ngIf="commercialSaleForm.get('rentalShare').invalid" class="error icon"></div>
                        </div>
                        <div class="ev-textarea">
                            <ev-textarea-reactive
                                [variant]="'big'"
                                [title]="''"
                                [placeholder]="'Lim inn annonsetekst her'"
                                [formControlNameInput]="'adText'"
                                [parentForm]="commercialSaleForm"
                            ></ev-textarea-reactive>
                        </div>
                    </div>
                    <!-- <div class="button-collection">
                        <div class="row">
                            <button (click)="saveCurrentSale()" [attr.disabled]="isSaving || commercialSaleForm.invalid ? true : null">Lagre</button>
                            <button (click)="resetSale()">Avbryt</button>
                        </div>
                    </div> -->
                    <div class="form__column">
                        <div class="lower_button-collection">
                            <search-estate (selectedRealEstateEvent)="addConnectedRealEstate($event)"></search-estate>
                            <div class="row">
                                <button class="save" (click)="saveCurrentSale()" [attr.disabled]="isSaving || commercialSaleForm.invalid ? true : null">Lagre</button>
                                <button class="cancel" (click)="resetSale()">Avbryt</button>
                            </div>
                        </div>
                        <ul>
                            <li *ngFor="let saleUnit of connectedSaleUnits">
                                <div title="{{ 'uid: ' + saleUnit.unitId }}">
                                    <div>{{ saleUnit.streetName }} {{ saleUnit.streetNumber }}{{ saleUnit.streetLetter }} , {{ saleUnit.postalCode }} {{ saleUnit.postOffice }}</div>
                                    <div>{{ saleUnit.cadastre }}</div>
                                </div>
                                <button class="light" (click)="deleteConnectedRealEstate(saleUnit)">Fjern</button>
                            </li>
                        </ul>
                        <div class="map-wrapper">
                            <static-map [mapMarkers]="connectedSaleUnitsMapMarkers$ | async" [index]="1"></static-map>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <ev-dialog [title]="'Søk etter Næringssalg'" [theme]="'green'" [visible]="showSearchSaleDialog" (close)="showSearchSaleDialog = false" [tooltip]="searchSaleTooltip" [hideFooter]="true">
            <search-sale (selectedSale)="navigateToSale($event)"></search-sale>
        </ev-dialog>
    </div>

    <div class="finn-text">
        <finn-text [adtext]="commercialSaleForm.get('adTextHtml').value"></finn-text>
    </div>
</div>

