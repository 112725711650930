import {Component, EventEmitter, Output} from "@angular/core";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import { SourceTypeId } from "src/app/commercial-sale/models/source-type-id.enum";
import {SaleQueryResponse} from "../../../commercial-sale/models/sale.model";
import {CommercialSaleService} from "../../../commercial-sale/services/commercial-sale.service";
import { EVEstateType } from "../../enums/estatetype.enum";
import {ToastService} from "../../services/toast.service";

@Component({
    selector: 'search-sale',
    templateUrl: './search-sale.component.html',
    styleUrls: ['./search-sale.component.scss', '../../../../assets/styles/search-modal.scss'],
    standalone: false
})
export class SearchSaleComponent {
    @Output() selectedSale: EventEmitter<number> = new EventEmitter();

    query = new UntypedFormControl('');
    searchForm = this.fb.group({query: this.query});
    searchResult: SaleQueryResponse[] = [];
    SourceTypeId = SourceTypeId;
    EVEstateType: EVEstateType;

    loading: boolean = false;

    constructor(private fb: UntypedFormBuilder, private sds: CommercialSaleService, private toastService: ToastService) {
    }

    search(): void {
        this.searchResult = [];
        this.loading = true;
        if (this.query.value !== '' && this.query.value != null && this.loading) {
            this.sds.searchSale(this.query.value).subscribe(
                (res) => {
                    this.searchResult = res;
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    this.toastService.setMessage({text: 'Noe feil har skjedd ved søk. Prøv igjen', type: 'error'})
                },
            )
        }
    }

    onSelectSale(estateSaleId: number | undefined): void {
        this.selectedSale.emit(estateSaleId);
    }

    iconClass(id: number): string {
        return EVEstateType[id];
    }
}
