import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Unit} from "../../../commercial-sale/models/unit.model";

@Component({
    selector: 'search-estate-results',
    templateUrl: './search-estate-results.component.html',
    styleUrls: ['./search-estate-results.component.scss'],
    standalone: false
})
export class SearchEstateResultsComponent {

  @Input() realEstates: Unit[];

  @Output() selectedRealEstate = new EventEmitter<Unit>();

  setSelectedRealEstate(realEstate: Unit) {
    this.selectedRealEstate.emit(realEstate);
  }

}
