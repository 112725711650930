"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WebatlasTileLayerTypes = void 0;
exports.WebatlasTileLayerTypes = {
  VECTOR: 'vector',
  AERIAL: 'aerial',
  HYBRID: 'hybrid',
  GREY: 'grey',
  MEDIUM: 'medium',
  LITE: 'lite'
};