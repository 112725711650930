import { Component } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { Subscription, of } from 'rxjs';
import { SharedModule } from '../shared/shared.module';
import { IDropDownItem } from 'ev-dropdown-reactive';
import { EnergyLabelService } from './services/energy-label.service';
import { ToastService } from '../shared/services/toast.service';
import { ReferenceTypesService } from '../shared/services/reference-types.service';
import { SearchEnergyComponent } from './components/search-energy/search-energy.component';
import { SearchEnergyConnectionsComponent } from './components/search-energy-connections/search-energy-connections.component';
import { EnergyLabel, EnergyLabelConnection, EnergyLabelCreateResponse } from './models/energy-label.model';
import { MapMarker } from '../shared/components/static-map/static-map.component';
import { WorkQueueService } from 'src/app/shared/services/workqueue.service';
import { WorkQueueSourceTableId } from 'src/app/shared/models/workqueue-source-table-id.enum';
import { WorkQueueItem } from 'src/app/shared/models/workqueue-item.model';
import { WorkQueueTypeId } from 'src/app/shared/models/workqueue-type-id.enum';

@Component({
    selector: 'app-energy-labels',
    imports: [SharedModule, SearchEnergyComponent, SearchEnergyConnectionsComponent],
    providers: [EnergyLabelService],
    templateUrl: './energy-labels.component.html',
    styleUrls: ['./energy-labels.component.scss', '../../assets/styles/form.scss']
})
export class EnergyLabelsComponent {
  numberPipe: DecimalPipe = new DecimalPipe('nb-NO');
  loading: boolean = false;
  inCreateMode: boolean = true;
  inReadOnlyMode: boolean = false;
  loadedEnergyLabel: EnergyLabel;
  certificateMethodItems: IDropDownItem[] = [];
  buildingMaterialItems: IDropDownItem[] = [];
  energyColorItems: IDropDownItem[] = [];
  buildingCategoryItems: IDropDownItem[] = [];
  showSearchEnergyDialog = false;
  showSearchEnergyLabelConnectionsDialog = false;
  connections: EnergyLabelConnection[] = [];
  selectedConnectionMapMarkers: MapMarker[] = [];
  remainingWorkqueue: number | string = '  ';
  loadingNextWorkqueueItem: boolean = false;
  currentWorkQueueItem: WorkQueueItem = null;

  energyPerformanceCertificateForm = new UntypedFormGroup({
    energyLabelID: new UntypedFormControl(null, [Validators.required]),
    energyLabelInfoID: new UntypedFormControl(null),
    issueDate: new UntypedFormControl(null, [Validators.required]),
    energyLabel: new UntypedFormControl(null, [Validators.required]),
    energyLabelingHeatingColorID: new UntypedFormControl(null, [Validators.required]),
    energyLabelCertificateMethodID: new UntypedFormControl(null, [Validators.required]),
    buildYear: new UntypedFormControl(null, [Validators.pattern("[0-9]*")]),
    energyBuildingCategoryID: new UntypedFormControl(null, [Validators.pattern("[0-9]*")]),
    buildingMaterialID: new UntypedFormControl(null, [Validators.pattern("[0-9]*")]),
    streetAddress: new UntypedFormControl(null),
    postCode: new UntypedFormControl(null),
    buildingNumber: new UntypedFormControl(null, [Validators.pattern("[0-9]*")]),
    functionalUnitNumber: new UntypedFormControl(null),
    organizationNumber: new UntypedFormControl(null, [Validators.pattern("[0-9]*")]),
    shareNumber: new UntypedFormControl(null, [Validators.pattern("[0-9]*")])
  });

  energyLabelItems: IDropDownItem[] = [
    {
        value: 'A',
        key: 'A',
    },
    {
        value: 'B',
        key: 'B',
    },
    {
        value: 'C',
        key: 'C',
    },
    {
        value: 'D',
        key: 'D',
    },
    {
        value: 'E',
        key: 'E',
    },
    {
        value: 'F',
        key: 'F',
    },
    {
        value: 'G',
        key: 'G',
    },
  ];

  private routeSub: Subscription;

  constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private toastService: ToastService,
      public energyLabelService: EnergyLabelService,
      private referenceTypes: ReferenceTypesService,
      private workqueueService: WorkQueueService,
  ) {}

  ngOnInit() {
    this.routeSub = this.activatedRoute.params.subscribe(params => {
      this.referenceTypes.getEnergyLabelCertificateMethods().subscribe((methods) => {
        this.certificateMethodItems = this.mapDropdownKeyValues(methods);
      });
      this.referenceTypes.getEnergyLabelingHeatingColors().subscribe((energyColors) => {
        this.energyColorItems = this.mapDropdownKeyValues(energyColors);
      });
      this.referenceTypes.getBuildingMaterials().subscribe((buildingMaterials) => {
        this.buildingMaterialItems = this.mapDropdownKeyValues(buildingMaterials);
      });
      this.referenceTypes.getEnergyBuildingCategories().subscribe((buildingCategories) => {
        this.buildingCategoryItems = this.mapDropdownKeyValues(buildingCategories);
      });
      
      const id = params['energyLabelInfoID'];
      if (id) {
        this.loadEnergyLabel(id);
      }
      if (params?.id != null) {
        this.currentWorkQueueItem = params as WorkQueueItem;
      } 
      else {
        this.currentWorkQueueItem = null;
      }

      this.getRemainingWorkQueue();
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  createNewMode(): void {
    if (this.currentWorkQueueItem) {
      this.workqueueService.putCommonWorkQueueReset(WorkQueueSourceTableId.EnergyLabel, this.currentWorkQueueItem.sourceTableIdentificationId).subscribe();
    }

    this.energyPerformanceCertificateForm.enable();
    this.energyPerformanceCertificateForm.reset();
    this.inCreateMode = true;
    this.inReadOnlyMode = false;
    this.loading = false;
    this.navigateToEnergyLabels('');
  }

  getNextFromWorkQueue() {
    this.loadingNextWorkqueueItem = true;
    
    this.workqueueService.getCommonWorkQueueNext(WorkQueueSourceTableId.EnergyLabel, WorkQueueTypeId.EnergyLabel)
    .pipe(
      catchError(error => {
        console.error(error);
        this.toastService.setMessage({ text: 'Noe feil har skjedd ved å hente workqueue. Prøv igjen', type: 'error' });
        this.loadingNextWorkqueueItem = false;
        return of({});
      })
    )
    .subscribe(
      (wi) => {
        const workQueueItem = wi as WorkQueueItem;
        this.navigateToEnergyLabels(workQueueItem.sourceTableIdentificationId, workQueueItem);
        this.loadingNextWorkqueueItem = false;
      });
  }

  putCommonWorkQueueHold() {
    this.workqueueService.putCommonWorkQueueHold(WorkQueueSourceTableId.EnergyLabel, this.currentWorkQueueItem.sourceTableIdentificationId).subscribe(() => {
      this.currentWorkQueueItem = null;
      this.createNewMode();
    });
  }

  save(): void {
    this.loading = true;

    if (this.inCreateMode) {
      this.energyPerformanceCertificateForm.controls.energyLabelInfoID.disable();
      let createObject = this.energyPerformanceCertificateForm.value as EnergyLabel;
      createObject.connections = this.connections;

      this.energyLabelService.createEnergyLabel(createObject).pipe(
        catchError(error => {
          console.error(error);
          this.toastService.setMessage({ text: 'Det oppstod en feil ved lagring: ' + error?.error?.Message, type: 'error' });
          this.energyPerformanceCertificateForm.controls.energyLabelID.enable();
          this.loading = false;
          
          return of({});
        })
      ).subscribe(x => {
        const resp = x as EnergyLabelCreateResponse;
        if (resp) {
          this.navigateToEnergyLabels(resp.energyLabelInfoID);

          if (resp.oneOrMoreConnectionsFailed) {
            this.toastService.setMessage({ text: 'Det oppstod en feil ved lagring av koblinger', type: 'error'});
          }
        }
      });
    }
    else {
      this.energyPerformanceCertificateForm.controls.energyLabelID.enable();
      let updateObject = this.energyPerformanceCertificateForm.value as EnergyLabel;
      updateObject.connections = this.connections;

      this.energyLabelService.updateEnergyLabel(updateObject).pipe(
        catchError(error => {
          console.error(error);
          this.toastService.setMessage({ text: 'Det oppstod en feil ved lagring: ' + error?.error?.Message, type: 'error' });
          this.loading = false;
          return of({});
        })
      ).subscribe(x => {
        this.setWorkQueueProcessed();
        this.loadEnergyLabel(this.loadedEnergyLabel.energyLabelInfoID);

        if (this.currentWorkQueueItem) {
          this.navigateToEnergyLabels(this.loadedEnergyLabel.energyLabelInfoID);
        }
      });
    }
  }

  edit(): void {
    this.inReadOnlyMode = false;
    this.energyPerformanceCertificateForm.enable();
    this.energyPerformanceCertificateForm.controls.energyLabelID.disable();
  }

  cancel(): void {
    this.loading = true;

    if (this.inCreateMode) {
      this.energyPerformanceCertificateForm.reset();
      this.router.navigate(['']);
    }
    else if (this.currentWorkQueueItem) {
      this.createNewMode();
    }
    else {
      this.energyPerformanceCertificateForm.reset(this.loadedEnergyLabel);
      this.energyPerformanceCertificateForm.disable();
      this.setConnections();
      this.inReadOnlyMode = true;
      this.updateMap();
    }

    this.loading = false;
  }

  onEnergySelected(id: string) {
    this.navigateToEnergyLabels(id);
    this.showSearchEnergyDialog = false;
  }

  onConnectionSelected(connection: EnergyLabelConnection) {
    this.showSearchEnergyLabelConnectionsDialog = false;
    if (!this.findConnection(connection)) {
      this.connections.push(connection);
    }

    this.updateMap();
  }

  removeConnection(connection: EnergyLabelConnection) {
    this.connections = this.connections.filter(x => x != this.findConnection(connection));
    this.updateMap();
  }

  getConnectionInfo(connection: EnergyLabelConnection): string {
    switch (connection.lvl) {
      case 1:
        return connection.unitInfo;
      case 2:
        return connection.buildingInfo
      case 3:
        return connection.functionalUnitInfo;
      default:
        return connection.unitInfo;  
    }
  }

  private getRemainingWorkQueue() {
    this.workqueueService.getCommonWorkQueueRemaining(WorkQueueSourceTableId.EnergyLabel).subscribe(q => this.remainingWorkqueue = q);
  }

  private setWorkQueueProcessed() {
    if (this.currentWorkQueueItem != null && this.currentWorkQueueItem.sourceTableIdentificationId != null) {
      this.workqueueService.putCommonWorkQueueProcessed(WorkQueueSourceTableId.EnergyLabel, this.currentWorkQueueItem.sourceTableIdentificationId).pipe(
        catchError(error => {
          console.error(error);
          this.toastService.setMessage({ text: 'Det oppstod en feil ved lagring av Workqueue: ' + error?.error?.Message, type: 'error' });
          return of({});
        })
      ).subscribe();
    }
  }

  private setConnections(): void {
    this.connections = [];

    if (this.loadedEnergyLabel.connections) {
      this.loadedEnergyLabel.connections.forEach(connection => {
        this.connections.push(connection);
      });
    }
  }

  private loadEnergyLabel(id: any) {
    this.loading = true;
    this.inCreateMode = false;
    this.energyPerformanceCertificateForm.controls.energyLabelInfoID.setValue(id);
    
    this.energyLabelService.getEnergyLabel(this.energyPerformanceCertificateForm.controls.energyLabelInfoID.value).pipe(
      catchError(error => {
        console.error(error);
        this.toastService.setMessage({ text: 'Det oppstod en feil ved henting av energiattest: ' + error?.error?.Message, type: 'error' });
        this.loading = false;
        return of({});
      })
    ).subscribe(x => {
      this.loadedEnergyLabel = x as EnergyLabel;
      this.energyPerformanceCertificateForm.reset(x);
      this.energyPerformanceCertificateForm.disable();
      this.setConnections();
      this.updateMap();
      this.inReadOnlyMode = true;
      this.loading = false;
    });
  }

  private navigateToEnergyLabels(id: number | string, workItem?: WorkQueueItem) {
    if (workItem) {
      this.router.navigate(['energylabels', id, workItem]);
    }
    else {
      this.router.navigate(['energylabels', id]);
    }
  }

  private findConnection(connection: EnergyLabelConnection): EnergyLabelConnection {
    return this.connections.find(x => 
      x.unitID === connection.unitID
      && x.unitInfo === connection.unitInfo
      && x.buildingInfo === connection.buildingInfo
      && x.unitInfo === connection.unitInfo
    );
  }

  private mapDropdownKeyValues(dropdownItems: { id: any; name: any; }[]) {
    return dropdownItems.map(({ id, name }) => ({ key: id, value: name }));
  }

  private updateMap() {
    let mapMarkers: MapMarker[] = [];
    this.connections.forEach(connection => {
      let mapMarker = new MapMarker();
      mapMarker.class = connection.estateTypeName;
      mapMarker.lat = connection.lat;
      mapMarker.lng = connection.lon;
      mapMarker.infolabel = connection.cadastre;
      mapMarkers.push(mapMarker);
    });

    this.selectedConnectionMapMarkers = mapMarkers;
  }
}
