import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { EstatesService } from "../../services/estates.service";
import { ToastService } from "../../../shared/services/toast.service";
import { Unit } from "../../models/unit.model";
import { ITooltip } from "ev-tooltip";
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'search-estate',
    templateUrl: './search-estate.component.html',
    styleUrls: ['./search-estate.component.scss'],
    standalone: false
})
export class SearchEstateComponent implements OnChanges {
  @Input('query') _query: string;
  @Output() selectedRealEstateEvent = new EventEmitter<Unit>();

  queryControl = new UntypedFormControl('');
  searchForm = this.fb.group({ query: this.queryControl });
  showConnectToRealEstate: boolean = false;
  realEstates: Unit[] = [];
  searchRealEstateInput: string = '';
  searchEstateTooltip: ITooltip = {
    show: false,
    header: '',
    info: [
      'Her skal du søke frem en matrikkel/adresse. Dette er et fritekstsøk der du for eksempel kan søke slik:',
      'Addresse "Storgaten 1 Oslo"',
      'Her kan du skrive kommune eller poststed. evt. også postnummer',
      'Matrikkel "301-234/23/0/7"',
      'Knr-Gnr/Bnr/Fnr/Snr. DU trenger ikke ta med Enr og Snr om disse er 0. Du kan også benytte "mellomrom/space" for å skille paramaterene "Knr Gnr Bnr Fnr Snr". Er du usikker på Knr kan kommunenavn benyttes "Oslo 234/23/0/7".',
    ],
    width: 300
  };

  loading: boolean = false;

  constructor(private realEstateService : EstatesService, private toastService: ToastService, private fb: UntypedFormBuilder) { }

  ngOnChanges() {
    if(this._query != null && this._query !== '') {
      this.realEstates = [];
      this.queryControl.setValue(this._query);
    }
  }

  searchRealEstate() {
    const searchInput = this.queryControl.value;

    if(searchInput !== '' && searchInput != null && !this.loading) {
      this.loading = true;

      this.realEstateService.searchEstates(searchInput).subscribe(
        (response) => {
          this.loading = false;
          this.realEstates = response;
        },
        (error) => {
          this.loading = false;
          this.toastService.setMessage({ text: 'Skjedd en feil søk Eiendom. Prøv på nytt', type: 'error'})
        })
    }

  }


  setSelectedRealEstate(selectedEstate: Unit) {
    this.selectedRealEstateEvent.emit(selectedEstate);
    this.showConnectToRealEstate = true;
    this.searchRealEstateInput = '';
  }
}
