"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultTilesets = void 0;
exports.defaultTilesets = {
  vector: {
    tileset: 'webatlas-standard-vektor',
    ext: 'png'
  },
  aerial: {
    tileset: 'webatlas-orto-newup',
    ext: 'jpeg'
  },
  hybrid: {
    tileset: 'webatlas-standard-hybrid',
    ext: 'jpeg'
  },
  grey: {
    tileset: 'webatlas-gray-vektor',
    ext: 'png'
  },
  medium: {
    tileset: 'webatlas-medium-vektor',
    ext: 'png'
  },
  lite: {
    tileset: 'webatlas-lite-vektor',
    ext: 'png'
  }
};