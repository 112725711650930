import { AfterViewInit,  Component, Input, OnChanges, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import * as L from 'leaflet';
import { webatlasTileLayer, WebatlasTileLayerTypes } from 'leaflet-webatlastile';

@Component({
    selector: 'static-map',
    templateUrl: './static-map.component.html',
    styleUrls: ['./static-map.component.scss'],
    standalone: false
})
export class StaticMapComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() mapMarkers: MapMarker[] = [];
  @Input() index: number = 0;

  mapId: string = 'map_';
  map: L.Map | undefined;
  markerLayer: L.LayerGroup | undefined;

  constructor() {}

  ngOnInit(): void {
    this.mapId = this.mapId + this.index + Math.random();
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  ngOnChanges() {
    if(this.mapMarkers != null) {
      this.createEstateMarker();
      this.setBounds();
    }
  }

  ngOnDestroy(): void {
    this.map.remove();
  }

  zoomIn(): void {
    this.map?.zoomIn(2);
  }

  zoomOut(): void {
    this.map?.zoomOut(2);
  }

  private initMap(): void {
    this.map = new L.Map(this.mapId, { zoomControl: false });
    webatlasTileLayer({ apiKey: '0420312a-121d-4139-bd48-16ca91e9866b' }).addTo(this.map);

    //this.setTileLayer();


    this.map.touchZoom.disable();
    this.map.scrollWheelZoom.disable();

    this.markerLayer = L.layerGroup();
    this.markerLayer.addTo(this.map);

    this.setBounds();
    this.createEstateMarker();

    setTimeout(() => {
      this.map.invalidateSize();
    }, 100);
  }

  private setTileLayer() {
    const tileType = {
      name: 'vectorTiles',
      description: 'Terreng',
      layer: webatlasTileLayer({
        mapType: WebatlasTileLayerTypes.VECTOR,
        apikey: '0420312a-121d-4139-bd48-16ca91e9866b',
        maxNativeZoom: 20,
        maxZoom: 20
      }),
    };
    this.map.addLayer(tileType.layer);
  }

  private setBounds() {
    if(this.map != null) {
      if(this.mapMarkers == null || this.mapMarkers.length === 0) {
          this.map.setView(new L.LatLng(59.91150299, 10.7417107), 11);
      } else {
        this.fitMarkerBounds();
      } 
    }
  }

  private fitMarkerBounds()  {
    if(this.mapMarkers.length === 1) {
      this.map.setView([this.mapMarkers[0].lat, this.mapMarkers[0].lng], 16);
    } else {
      const latsort = [...this.mapMarkers].sort((a,b) => a.lat - b.lat);
      const lngsort = [...this.mapMarkers].sort((a,b) => a.lng - b.lng);

      const maxCorner = L.latLng(latsort[0].lat, lngsort[0].lng);
      const minCorner = L.latLng(latsort[latsort.length -1].lat, lngsort[lngsort.length -1].lng);
      
      this.map.fitBounds(L.latLngBounds(maxCorner, minCorner));
    }
  }

  private createEstateMarker(): void {
    if (this.map != null){
      
      this.markerLayer.clearLayers();

      this.mapMarkers.forEach(m => {
        const iconClass= m.class != null ? 'estate-icon ' + m.class: 'estate-icon'
        const icon: L.DivIcon = L.divIcon({
          className: '',
          iconAnchor: [16, 16],
          html: `<div class="${iconClass}" title="${m.infolabel}"></div>`
        });

        L.marker([m.lat, m.lng], { icon: icon }).addTo(this.markerLayer);
      })
    }
  }
}

export class MapMarker {
  class: string;
  infolabel: string;
  lat: number;
  lng: number;
}
